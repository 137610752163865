module.exports = {
  sassVariables: {
    primary: '#7dc535',
    secondary: '#272523',
    'navbar-back-rotation': '145deg',
    'navbar-link-rotation': '75deg',
    'login-background': "url('/images/fighterzleague_slide.jpg')",
    'secondary-background': "''",
  },
  images: {
    loginLogo: '/images/white-logo.png',
    fullLogo: '/images/full-logo.png',
    overviewLogo: '/images/full-logo.png',
    faviconLogo: '/images/logo-icon.png',
    poweredByAdvocate: '/images/powered-by-advocate.png',
  },
}
